.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 24px;

  align-items: center;
  justify-content: center;

  img {
    max-width: 300px;
    width: 100%;
  }
  
  h1 {
    margin: 24px 0 12px;
    font-family: 'Roboto';
  }
  

  @media screen and (max-width: 800px) {
    padding: 12px;

    img {
      width: 200px
    }
  }
}

.textContainer {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}
